<i18n>
{
  "de": {
    "exportButton": "JSON-Export ohne GIS-Daten",
    "exportButtonWithGis": "JSON-Export mit GIS-Daten"
  }
}
</i18n>

<template>
  <ButtonWrapper>
    <button v-if="portfolio" class="button" @click="onExport">{{ $t('exportButton') }}</button>
    <button v-if="portfolio" class="button" @click="onExportWithGis">{{ $t('exportButtonWithGis') }}</button>
  </ButtonWrapper>
</template>

<script>
import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'

import exportService from '@/services/export-service.js'

export default {
  components: {
    ButtonWrapper,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  methods: {
    onExport() {
      exportService.exportPortfolioJson(this.portfolio, false)
    },

    onExportWithGis() {
      exportService.exportPortfolioJson(this.portfolio, true)
    },
  },
}
</script>
